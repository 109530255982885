var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("m-base-card-new", {
    attrs: { element_type: "lecture" },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _c("MBaseCardHeaderNew", {
              attrs: {
                title: _vm.lecture.title,
                "available-date": !_vm.$language
                  ? "Available Date : " + _vm.lecture.share_date
                  : "প্রকাশের তারিখ : " +
                    new Date(_vm.lecture.share_date).toLocaleString("bn-BD", {
                      dateStyle: "long",
                    }),
                element_type: "lecture",
              },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "middle-content",
        fn: function () {
          return [_c("div", { staticStyle: { display: "none" } })]
        },
        proxy: true,
      },
      {
        key: "footer",
        fn: function () {
          return [
            !_vm.$language
              ? _c("MBaseCardFooter", {
                  attrs: {
                    element_1: _vm.lecture.is_video ? "Play" : "Preview",
                    element_type: "lecture",
                  },
                  on: { footer_element_1: _vm.download },
                })
              : _c("MBaseCardFooter", {
                  attrs: {
                    element_1: _vm.lecture.is_video ? "চালু করো" : "দেখো",
                    element_type: "lecture",
                  },
                  on: { footer_element_1: _vm.download },
                }),
            _c(
              "v-dialog",
              {
                attrs: { width: "100vw" },
                model: {
                  value: _vm.isFilePreviewerVisible,
                  callback: function ($$v) {
                    _vm.isFilePreviewerVisible = $$v
                  },
                  expression: "isFilePreviewerVisible",
                },
              },
              [
                _vm.isFilePreviewerVisible
                  ? _c(
                      "file-previewer",
                      {
                        attrs: {
                          "show-annotation": _vm.showAnnotation,
                          "preview-url": _vm.previewUrl,
                          "download-url": _vm.downloadUrl,
                          "lecture-id": _vm.lecture.object_id,
                          "share-date": _vm.lecture.share_date,
                          title: _vm.lecture.title,
                          "is-lecture": true,
                          "is-video": _vm.lecture.is_video,
                          "is-download": _vm.lecture.is_downloadable,
                        },
                        on: {
                          "update:showAnnotation": function ($event) {
                            _vm.showAnnotation = $event
                          },
                          "update:show-annotation": function ($event) {
                            _vm.showAnnotation = $event
                          },
                          "show-annotation": function ($event) {
                            _vm.showAnnotation = true
                          },
                          "hide-annotation": function ($event) {
                            _vm.showAnnotation = false
                          },
                          cancel: function ($event) {
                            _vm.isFilePreviewerVisible = false
                          },
                        },
                      },
                      [
                        _c("annotations-component", {
                          attrs: {
                            slot: _vm.activeSlot,
                            "lecture-id": _vm.lecture.object_id,
                          },
                          on: {
                            "hide-annotation": function ($event) {
                              _vm.showAnnotation = false
                            },
                          },
                          slot: _vm.activeSlot,
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }