<template>
  <m-base-card-new :element_type="'lecture'">
    <template v-slot:header>
      <MBaseCardHeaderNew
        :title="lecture.title"
        :available-date="
          !$language
            ? 'Available Date : ' + lecture.share_date
            : 'প্রকাশের তারিখ : ' +
              new Date(lecture.share_date).toLocaleString('bn-BD', {
                dateStyle: 'long'
              })
        "
        :element_type="'lecture'"
      ></MBaseCardHeaderNew>
    </template>
    <template v-slot:middle-content>
      <div style="display:none;"></div>
    </template>
    <template v-slot:footer>
      <MBaseCardFooter
        v-if="!$language"
        :element_1="lecture.is_video ? 'Play' : 'Preview'"
        :element_type="'lecture'"
        @footer_element_1="download"
      ></MBaseCardFooter>
      <MBaseCardFooter
        v-else
        :element_1="lecture.is_video ? 'চালু করো' : 'দেখো'"
        :element_type="'lecture'"
        @footer_element_1="download"
      ></MBaseCardFooter>
      <v-dialog v-model="isFilePreviewerVisible" width="100vw">
        <file-previewer
          v-if="isFilePreviewerVisible"
          :show-annotation.sync="showAnnotation"
          :preview-url="previewUrl"
          :download-url="downloadUrl"
          :lecture-id="lecture.object_id"
          :share-date="lecture.share_date"
          :title="lecture.title"
          :is-lecture="true"
          :is-video="lecture.is_video"
          :is-download="lecture.is_downloadable"
          @show-annotation="showAnnotation = true"
          @hide-annotation="showAnnotation = false"
          @cancel="isFilePreviewerVisible = false"
        >
          <annotations-component
            :slot="activeSlot"
            :lecture-id="lecture.object_id"
            @hide-annotation="showAnnotation = false"
          ></annotations-component>
        </file-previewer>
      </v-dialog>
    </template>
  </m-base-card-new>
</template>

<script>
import FilePreviewer from "~ef/components/FilePreviewer";
import MBaseCardNew from "~ef/components/MBaseCardNew.vue";
import MBaseCardHeaderNew from "~ef/components/MBaseCardHeaderNew.vue";
import MBaseCardFooter from "~ef/components/MBaseCardFooter.vue";
import lectureService from "../services/LectureService";
import AnnotationsComponent from "#ef/lecture/components/AnnotationsComponent";

export default {
  name: "MLectureListItem",
  components: {
    AnnotationsComponent,
    FilePreviewer,
    MBaseCardNew,
    MBaseCardHeaderNew,
    MBaseCardFooter
  },
  props: {
    lecture: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showAnnotation: false,
      previewUrl: undefined,
      downloadUrl: undefined,
      isFilePreviewerVisible: false
    };
  },

  computed: {
    activeSlot() {
      if (this.showAnnotation && !this.mobile && this.lecture.is_video)
        return "lecture_is_video";
      else if (this.showAnnotation && !this.mobile && !this.lecture.is_video)
        return "lecture_is_not_video";
      else if (this.mobile) return "lecture_mobile";
      return "";
    },
    mobile() {
      return this.$vuetify.breakpoint.width <= 600;
    }
  },

  created() {},
  methods: {
    async download() {
      try {
        let a = await lectureService.get(
          this.lecture.object_id,
          this.$ielts_batch_id
        );
        this.isFilePreviewerVisible = true;
        this.previewUrl = a.preview_url;
        this.downloadUrl = a.download_url;
      } catch (e) {
        if (e.cause !== undefined) {
          this.$root.$emit("alert-access", {
            message: e.message,
            cause: e.cause,
            url: window.location.href
          });
        } else {
          this.$root.$emit("alert", [undefined, e.message]);
          this.isFilePreviewerVisible = false;
          this.lectureUrl = "unauthorized";
        }
      }
    }
  }
};
</script>

<style lang="scss">
.m-lecture-list-item {
  font-family: Poppins, sans-serif !important;
  color: #3d3d3d !important;

  .v-card__text {
    color: #3d3d3d !important;
  }

  button {
    font-family: Poppins, sans-serif !important;
  }
}
</style>
